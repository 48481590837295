(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/react-conditionals/assets/javascripts/otherwise.js') >= 0) return;  svs.modules.push('/components/lb-utils/react-conditionals/assets/javascripts/otherwise.js');
"use strict";

const Otherwise = _ref => {
  let {
    children
  } = _ref;
  return children;
};
Otherwise.propTypes = {
  children: PropTypes.node.isRequired
};
setGlobal('svs.components.lbUtils.reactConditionals.Otherwise', Otherwise);

 })(window);
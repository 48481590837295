(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/react-conditionals/assets/javascripts/for-each.js') >= 0) return;  svs.modules.push('/components/lb-utils/react-conditionals/assets/javascripts/for-each.js');
"use strict";

const {
  Null
} = svs.components.lbUtils.reactConditionals;
const ForEach = _ref => {
  let {
    array,
    render,
    fallback
  } = _ref;
  if (Array.isArray(array) && array.length > 0) {
    return array.map(render);
  }
  return fallback || React.createElement(Null, null);
};
setGlobal('svs.components.lbUtils.reactConditionals.ForEach', ForEach);

 })(window);
(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/react-conditionals/assets/javascripts/choose.js') >= 0) return;  svs.modules.push('/components/lb-utils/react-conditionals/assets/javascripts/choose.js');
"use strict";

const {
  Null,
  When,
  Otherwise
} = svs.components.lbUtils.reactConditionals;
const checkWhenLogic = el => el.type === When && el.props.condition;
const checkOtherwise = el => el.type === Otherwise;
const Choose = _ref => {
  let {
    children
  } = _ref;
  let otherWiseInternal;
  const childrenIsArray = Array.isArray(children);
  if (!childrenIsArray || children.length < 2) {
    throw new Error('Only use <Choose> with more than one child, otherwise use <If />');
  }
  const childrenLength = children.length;
  for (let i = 0; i < childrenLength; i++) {
    const el = children[i];
    if (checkWhenLogic(el)) {
      return el;
    }
    if (!otherWiseInternal && checkOtherwise(el)) {
      otherWiseInternal = el;
    }
  }
  if (otherWiseInternal) {
    return otherWiseInternal;
  }
  return React.createElement(Null, null);
};
setGlobal('svs.components.lbUtils.reactConditionals.Choose', Choose);

 })(window);
(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/react-conditionals/assets/javascripts/when.js') >= 0) return;  svs.modules.push('/components/lb-utils/react-conditionals/assets/javascripts/when.js');
"use strict";

const {
  Null
} = svs.components.lbUtils.reactConditionals;
const When = _ref => {
  let {
    condition,
    children
  } = _ref;
  return !condition ? React.createElement(Null, null) : children;
};
setGlobal('svs.components.lbUtils.reactConditionals.When', When);

 })(window);